<template>
  <Clients></Clients>
</template>

<script>
export default {
  name: 'CoachDashboard',
  components: {
    Clients: () => import('@/views/Clients'),
  },
};
</script>
